<template>
  <div>
    <v-card class="d-flex justify-end mb-6" flat tile>
      <v-breadcrumbs :items="breadcrumbs" large>
        <template v-slot:divider>
          <v-icon>{{ icons.mdiSlashForward }} </v-icon>
        </template>
      </v-breadcrumbs>
    </v-card>
    <v-card flat class="pa-3 mt-2">
      <v-card-text class="d-flex">
        <v-avatar rounded size="120" class="me-6">
          <v-img :src="defautlPhoto()"></v-img>
        </v-avatar>

        <!-- upload photo -->
        <!-- <div> -->
        <v-col cols="10">
          <div style="padding-top: 25px">
            <v-alert type="error">
              Pastikan anda memasukkan produk barang dengan benar di toko <strong> {{ showtokos.nama_toko }}</strong
              >.
            </v-alert>
          </div>
        </v-col>
      </v-card-text>

      <v-card-text>
        <!-- <v-form class="multi-col-validation mt-6"> -->
        <form ref="form" enctype="multipart/form-data" @submit.prevent="CreateData">
          <v-row>
            <v-col md="12" cols="12">
              <v-select v-model="jadwal.hari" :items="hari" label="Hari" solo></v-select>
            </v-col>

            <v-col md="6" cols="12">
              <v-header>Jam Buka &nbsp;</v-header>
              <vue-timepicker v-model="jadwal.jam_buka"></vue-timepicker>
            </v-col>
            <v-col md="6" cols="12">
              <v-header>Jam Tutup &nbsp;</v-header>
              <vue-timepicker v-model="jadwal.jam_tutup"></vue-timepicker>
            </v-col>

            <v-col md="12" cols="12">
              <v-header>Tutup Sementara</v-header>
              <v-radio-group v-model="jadwal.tutup_sementara" row>
                <v-radio label="Yes" value="Y" color="info"></v-radio>
                <v-radio label="No" value="T" color="error"></v-radio>
              </v-radio-group>
            </v-col>

            <v-col md="12" cols="12">
              <v-header>Tutup Total</v-header>
              <v-radio-group v-model="jadwal.tutup_total" row>
                <v-radio label="Yes" value="Y" color="info"></v-radio>
                <v-radio label="No" value="T" color="error"></v-radio>
              </v-radio-group>
            </v-col>

            <v-col cols="12">
              <v-btn type="submit" color="primary" class="me-3 mt-4"> Save </v-btn>

              <router-link :to="{ name: 'tokos-index' }">
                <v-btn color="secondary" outlined class="mt-4" type="reset"> Cancel </v-btn>
              </router-link>
            </v-col>
          </v-row>
        </form>
        <!-- </v-form> -->
      </v-card-text> </v-card
    ><br />
    <v-card>
      <v-card-text>
        <v-card-title class="text-no-wrap pt-1 ps-2"> JADWAL</v-card-title>

        <v-text-field
          class="pt-5"
          label="Search"
          @keyup="doSearchProduks()"
          v-model="search"
          dense
          outlined
        ></v-text-field>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-uppercase">NO</th>
                <th class="text-center text-uppercase">HARI</th>
                <th class="text-center text-uppercase">BUKA</th>
                <th class="text-center text-uppercase">TUTUP</th>
                <th class="text-center text-uppercase">ACTION</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(data, index) in displayDataProduks()" :key="index">
                <td>{{ index + 1 }}</td>
                <td class="text-center">{{ data.hari | capitalize }}</td>
                <td class="text-center">
                  {{ data.jam_buka }}
                </td>

                <td class="text-center">
                  {{ data.jam_tutup }}
                </td>

                <td class="text-center">
                  <v-btn
                    :to="{
                      name: 'tokos-jadwal-edit',
                      params: { id_jadwal: data.id_jadwal, id_toko: data.id_toko },
                    }"
                    color="warning"
                    class="me-3 mt-4"
                  >
                    <v-icon color="#000000">
                      {{ icons.mdiGreasePencil }}
                    </v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="8">
                  <center>
                    <div class="v-data-footer">
                      <!-- <div class="v-data-footer__pagination" bis_skin_checked="1">1-10 of 100</div> -->
                      <div class="v-data-footer__icons-before" bis_skin_checked="1">
                        <button
                          type="button"
                          :class="[
                            'v-btn  v-btn--icon v-btn--round v-btn--text theme--light v-size--default',
                            pageProduks == 1 ? 'v-btn--disabled' : '',
                          ]"
                          aria-label="Previous page"
                          @click="pageProduks--"
                        >
                          <span class="v-btn__content"
                            ><span aria-hidden="true" class="v-icon notranslate theme--light"
                              ><svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                role="img"
                                aria-hidden="true"
                                class="v-icon__svg"
                              >
                                <path
                                  d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"
                                ></path></svg></span
                          ></span>
                        </button>
                      </div>
                      <div
                        v-for="pageNumber in pagesProduks.slice(pageProduks - 1, pageProduks + 5)"
                        :key="pageNumber"
                        :class="['v-data-footer', pageNumber == pageProduks ? 'active' : '']"
                        @click="pageProduks = pageNumber"
                      >
                        <!-- <a href="#">{{ pageNumber }}</a> -->
                        <button
                          type="button"
                          class="v-btn v-btn--icon v-btn--round v-btn--text theme--light v-size--default"
                          aria-label="Next page"
                        >
                          {{ pageNumber }}
                        </button>
                      </div>
                      <div class="v-data-footer__icons-after" bis_skin_checked="1">
                        <button
                          type="button"
                          :class="[
                            'v-btn v-btn--icon v-btn--round v-btn--text theme--light v-size--default',
                            pageProduks < pagesProduks.length ? '' : 'v-btn--disabled',
                          ]"
                          aria-label="Next page"
                          @click="pageProduks++"
                        >
                          <span class="v-btn__content"
                            ><span aria-hidden="true" class="v-icon notranslate theme--light"
                              ><svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                role="img"
                                aria-hidden="true"
                                class="v-icon__svg"
                              >
                                <path
                                  d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"
                                ></path></svg></span
                          ></span>
                        </button>
                      </div>
                    </div>
                  </center>
                </td>
              </tr>
            </tfoot>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
// Manually point to the `/src` folder
import VueTimepicker from 'vue2-timepicker/src'
// Or, to the specific file name
// import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'

import {
  mdiCheckBold,
  mdiAccountOutline,
  mdiCurrencyUsd,
  mdiTrendingUp,
  mdiDotsVertical,
  mdiLabelOutline,
  mdiDelete,
  mdiGreasePencil,
  mdiContentSave,
  mdiSlashForward,
  mdiStoreEdit,
} from '@mdi/js'

import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'

export default {
  components: {
    VueCropper,
    VueTimepicker,
  },
  data() {
    const resolveStatisticsIconVariation = data => {
      if (data === 'Visa') return { icon: mdiTrendingUp, color: 'primary' }
      if (data === 'Members') return { icon: mdiAccountOutline, color: 'success' }
      if (data === 'Product') return { icon: mdiLabelOutline, color: 'warning' }
      if (data === 'Revenue') return { icon: mdiCurrencyUsd, color: 'info' }

      return { icon: mdiAccountOutline, color: 'success' }
    }
    return {
      resolveStatisticsIconVariation,

      breadcrumbs: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/admin/dashboard',
        },
        {
          text: 'Toko',
          disabled: false,
          href: '/admin/tokos',
        },
        {
          text: 'Jadwal',
          disabled: true,
        },
      ],

      // icons
      icons: {
        mdiCheckBold,
        mdiGreasePencil,
        mdiDelete,
        mdiContentSave,
        mdiDotsVertical,
        mdiTrendingUp,
        mdiAccountOutline,
        mdiLabelOutline,
        mdiCurrencyUsd,
        mdiSlashForward,
        mdiStoreEdit,
      },

      hari: ['Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu', 'Minggu'],

      jadwal: {
        hari: '',
        jam_buka: '',
        jam_tutup: '',
        tutup_sementara: 'T',
        tutup_total: 'T',
      },
      produkbarang: {
        stok: 0,
        berat: 0,
        harga: '',
      },
      gambarprodukbarang: {
        photo_produkbarang: [],
      },

      nama_kategoriproduk: '',
      dataproduks: [],
      gambarproduks: [],

      rulesstok: {
        numberRule: val => {
          if (val < 0) return 'Please enter a positive number'
          return true
        },
      },

      rulesberat: {
        numberRule: val => {
          if (val < 0) return 'Please enter a positive number'
          return true
        },
      },

      jadwals: [],

      kategoriproduk: [],
      showtokos: [],

      page: 1,
      perPage: 10,
      pages: [],
      search: '',
      searchResult: [],

      pageProduks: 1,
      perPageProduks: 10,
      pagesProduks: [],
      searchProduks: '',
      searchResultProduks: [],
    }
  },

  mounted() {
    // this.getAllData()
    this.$isLoading(true)
    this.showGambarProduk()
    this.getAllDataProduk()
    this.GetToko()
    setTimeout(() => {
      this.$isLoading(false)
    }, 2000)
  },
  filters: {
    capitalize: function (data) {
      var capitalized = []
      data.split(' ').forEach(word => {
        capitalized.push(word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      })
      return capitalized.join(' ')
    },
  },
  methods: {
    showGambarProduk() {
      const imageProduks = `${process.env.VUE_APP_ROOT_API}`
      return imageProduks
    },

    getAllDataProduk() {
      //state token
      localStorage.getItem('token')

      let url = process.env.VUE_APP_ROOT_API + `/api/admin/produks/${this.$route.params.id_toko}`
      return this.axios.get(url).then(response => {
        this.dataproduks = response.data.dataproduk
        this.gambarproduks = response.data.gambarproduk
        // console.log('NAMA ROLE: ' + response.data.users.role_rel.nama_role)
      })
    },

    CreateData(e) {
      // console.log('PHOTO: ' + this.produk.id_kategoriproduk)
      if (this.$data.jadwal.hari.length === 0) {
        // this.errors.push('Nama Role harus diisi !')
        this.$swal.fire({
          title: 'Error',
          text: 'Hari required !',
          icon: 'error',
          showCancelButton: false,
          showConfirmButton: false,
          timer: 1000,
        })
      } else if (this.$data.jadwal.jam_buka.length === 0) {
        this.$swal.fire({
          title: 'Error',
          text: 'Jam Buka required !',
          icon: 'error',
          showCancelButton: false,
          showConfirmButton: false,
          timer: 1000,
        })
      } else if (this.$data.jadwal.jam_tutup.length === 0) {
        this.$swal.fire({
          title: 'Error',
          text: 'Jam Tutup required !',
          icon: 'error',
          showCancelButton: false,
          showConfirmButton: false,
          timer: 1000,
        })
      } else {
        const uri = `${process.env.VUE_APP_ROOT_API}/api/admin/jadwals/store`

        this.axios
          .post(uri, {
            id_toko: this.$route.params.id_toko,
            hari: this.jadwal.hari,
            jam_buka: this.jadwal.jam_buka,
            jam_tutup: this.jadwal.jam_tutup,
            tutup_sementara: this.jadwal.tutup_sementara,
            tutup_total: this.jadwal.tutup_total,
          })
          .then(response => {
            this.$swal.fire({
              title: 'Success',
              text: 'Jadwal created successfully',
              icon: 'success',
              showCancelButton: false,
              showConfirmButton: false,
              timer: 1000,
            })

            setTimeout(() => {
              // this.$router.push({ name: 'produk-index' })
              window.location.reload()
            }, 3000)
          })

        e.preventDefault()
      }
    },

    defautlPhoto() {
      const imageDefault = `${process.env.VUE_APP_ROOT_API}/images/icon/tokobarang.png`
      return imageDefault
    },

    // getAllData() {
    //   //state token
    //   localStorage.getItem('token')

    //   let url = process.env.VUE_APP_ROOT_API + '/api/admin/kategoriproduks'
    //   return this.axios.get(url).then(response => {
    //     this.kategoriproduk = response.data.kategoriproduks
    //   })
    // },

    GetToko() {
      // let uri = `/api/admin/roles/${this.$route.params.id_role}/show`
      let uri = process.env.VUE_APP_ROOT_API + `/api/admin/jadwals/${this.$route.params.id_toko}`
      this.axios.get(uri).then(response => {
        this.showtokos = response.data.showtoko
        this.jadwals = response.data.jadwal
        // console.log(this.role.nama_role)
      })
    },

    // Paging Produk
    doSearchProduks() {
      const go = this.jadwals.filter(item =>
        this.searchProduks
          .toLowerCase()
          .split(' ')
          .every(v => item.hari.toLowerCase().includes(v)),
      )
      this.searchResultProduks = go
      this.pagesProduks = []
      this.setPagesDataProduks()
    },
    displayDataProduks() {
      let paginateProduks

      if (this.searchProduks == '') {
        paginateProduks = this.paginateProduks(this.jadwals)
      } else {
        paginateProduks = this.paginateProduks(this.searchResultProduks)
      }

      return paginateProduks
    },
    setPagesDataProduks() {
      let numberOfPages

      if (this.searchProduks == '') {
        numberOfPages = Math.ceil(this.jadwals.length / this.perPageProduks)
      } else {
        numberOfPages = Math.ceil(this.searchResultProduks.length / this.perPageProduks)
      }
      for (let index = 1; index <= numberOfPages; index++) {
        this.pagesProduks.push(index)
      }
    },
    paginateProduks(jadwals) {
      const { pageProduks } = this
      const { perPageProduks } = this
      const from = pageProduks * perPageProduks - perPageProduks
      const to = pageProduks * perPageProduks

      return jadwals.slice(from, to)
    },
    // End Paging Produk

    doSearch() {
      const go = this.kategoriproduk.filter(item =>
        this.search
          .toLowerCase()
          .split(' ')
          .every(v => item.nama_kategoriproduk.toLowerCase().includes(v)),
      )
      this.searchResult = go
      this.pages = []
      this.setPages()
    },
    displayData() {
      let paginate

      if (this.search == '') {
        paginate = this.paginate(this.kategoriproduk)
      } else {
        paginate = this.paginate(this.searchResult)
      }

      return paginate
    },
    setPages() {
      let numberOfPages

      if (this.search == '') {
        numberOfPages = Math.ceil(this.kategoriproduk.length / this.perPage)
      } else {
        numberOfPages = Math.ceil(this.searchResult.length / this.perPage)
      }
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index)
      }
    },
    paginate(kategoriproduk) {
      const { page } = this
      const { perPage } = this
      const from = page * perPage - perPage
      const to = page * perPage

      return kategoriproduk.slice(from, to)
    },
  },
  watch: {
    kategoriproduk() {
      this.pages = []
      this.setPages()
    },

    jadwals() {
      this.pagesProduks = []
      this.setPagesDataProduks()
    },
  },
}
</script>
